export default class Faq{
	constructor(){
		const foldables = document.querySelectorAll(".faq__foldable");
		foldables.forEach(foldable=>this.initFoldable(foldable));
	}
	initFoldable(foldable){
		const question = foldable.querySelector(".faq__foldable__q");
		const answer = foldable.querySelector(".faq__foldable__a");
		const contents = foldable.querySelector(".faq__foldable__a__contents");
		let isOpen = false;
		let timer = null;

		foldable.addEventListener("click", ()=>{
			if(timer) clearTimeout(timer);
			if(isOpen){
				foldable.classList.remove("open");
				isOpen = false;
			}else{
				foldable.classList.add("open");
				isOpen = true;
			}
			resize();
		});

		function resize(){
			if(isOpen){
				answer.style.height = contents.offsetHeight+"px";
			}else{
				answer.style.height = "0px";
			}
		}
		window.addEventListener("resize", resize, false);
	}
}