import anime from 'animejs/lib/anime.es.js';
export default class AnchorScroll{
	constructor(){
		this.scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
		this.animation = null;
		this.isScrolling = false;
		this.duration = 750;

		window.addEventListener('wheel', ()=>{ this.scrollHandler() }, {passive: true});
		this.bindSmoothScroll();
	}

	scrollHandler(){
		if(this.animation && this.isScrolling){
			this.animation.pause();
			this.isScrolling = false;
		}
	}

	smoothScroll(btn){
		const href = btn.getAttribute("href");
		btn.addEventListener('click', (e)=>{
			e.preventDefault();
			e.stopPropagation();
			if(this.isScrolling) return false;
			let targetPosition = 0;
			if(href.length > 1){
				const targetDom = document.querySelector(href);
				if(targetDom){
					const rect = targetDom.getBoundingClientRect();
					const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
					targetPosition = rect.top + scrollTop;
				}
			}
			this.animation = anime({
				targets: this.scrollElement,
				scrollTop: targetPosition,
				duration: this.duration,
				easing: 'easeOutExpo',
				complete: ()=>{
					this.isScrolling = false;
				}
			});
			this.isScrolling = true;
			return false;
		}, false);
	}

	bindSmoothScroll(){
		const buttons = document.querySelectorAll('a[href^="#"]');
		buttons.forEach((btn)=>{ this.smoothScroll(btn) });
	}
}