export default class MailForm{
	constructor(){
		const form = document.querySelector('#contactform');
		if(form) this.initialize(form)
	}

	initialize(form){
		const sendButton = form.querySelector('.contact__form__send > button');
		const messages = form.querySelectorAll('.contact__form__message');
		const erroritems = form.querySelectorAll('.contact__form__message.invalid li');
		const sendPath = "/contact/send/";


		sendButton.addEventListener('click',  (e) => {
			e.preventDefault();
			const XHR = new XMLHttpRequest();
			const formData = new FormData(form);

			messages.forEach((msg) =>{
				msg.classList.remove('show');
			});
			erroritems.forEach((msg) =>{
				msg.classList.remove('show');
			});

			sendButton.classList.add('processing');
			sendButton.innerText = "送信中";

			XHR.addEventListener('load', (res) => {
				if(res.target.readyState == 4 && res.target.status == 200){
					const response = res.target.response;
					if(response){
						if(response.status){
							if(response.status == 'invalid'){
								formInvalid(response.error)
							}else if(response.status == 'ok'){
								formDone();
							}else{
								formError()
							}
						}else{
							formError()
						}
					}
				}else{
					formError();
				}
			});
			XHR.addEventListener('error', (res) => {
				formError();
			});
			XHR.open('POST', sendPath, true);
			XHR.responseType = 'json';
			XHR.send(formData);
		}, false);

		function formError(){
			form.querySelector('.contact__form__message.error').classList.add('show');
			sendButton.classList.remove('processing');
			sendButton.innerText = "送信";
		}
		function formInvalid(errors){
			form.querySelector('.contact__form__message.invalid').classList.add('show');
			for(var i=0; i<errors.length; i++){
				form.querySelector('.contact__form__message.invalid li.'+errors[i]).classList.add('show');
			}
			sendButton.classList.remove('processing');
			sendButton.innerText = "送信";
		}
		function formDone(){
			form.querySelector('.contact__form__message.success').classList.add('show');
			sendButton.classList.remove('processing');
			sendButton.classList.add('done');
			sendButton.innerText = "送信完了";
		}


		//プルダウン
		form.querySelectorAll('.contact__form__select').forEach((block)=>{
			const selection = block.querySelector('select');
			const label = block.querySelector('.contact__form__select__label');
			selection.addEventListener("change", (e)=>{
				label.innerText = selection.value
			});
		});
	}
}