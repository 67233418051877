export default class Header{
	constructor(){
		const header = document.querySelector(".header");
		const contents = document.querySelector(".header__contents");
		const toggle = document.querySelector(".header__toggle");
		let isOpen = false;

		window.addEventListener("resize", onResize, false);
		toggle.addEventListener("click", ()=>{
			if(isOpen){
				header.classList.remove("open");
				isOpen = false;
			}else{
				header.classList.add("open");
				isOpen = true;
			}
			onResize();
		}, false);

		function onResize(){
			if(isOpen){
				header.style.height = window.innerHeight+"px";
			}else{
				header.style.height = "60px";
			}
			contents.style.height = (window.innerHeight-60)+"px";
		}
	}
}