export default class SlideBanners{
	constructor(){
		const slideBanners = document.querySelectorAll('.slidebanners');
		if(slideBanners){
			slideBanners.forEach(banner=>this.initSlideBanner(banner));
		}
	}
	initSlideBanner(banner){
		const slides = banner.querySelectorAll('.slidebanners__slide');
		const maxnum = slides.length;
		let position = 0;

		function moveSlide(){
			banner.dataset.position = position;
			position = (position+1 < maxnum) ? position+1 : 0;
			setTimeout(moveSlide, 7000);
		}

		setTimeout(moveSlide, 100);
	}
}