import 'nodelist-foreach-polyfill';
import Header from './modules/_header.js';
import SpPageNavi from './modules/_sppagenavi.js';
import SlideBanners from './modules/_slidebanners.js';
import Faq from './modules/_faq.js';
import MailForm from './modules/_mailform.js';
import AnchorScroll from './modules/_anchorscroll.js';

class App {
	constructor() {
		this.header = new Header();
		this.sppagenavimod = new SpPageNavi();
		this.slidebanners = new SlideBanners();
		this.faq = new Faq();
		this.mailform = new MailForm();
		this.anchorscroll = new AnchorScroll();
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.isTouch = false;
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}
	let app = new App();
}, false);