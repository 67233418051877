export default class SpPageNavi{
	constructor(){
		const pageNavi = document.querySelector(".page__nav");
		if(pageNavi){
			if(!pageNavi.classList.contains("domestic")) this.initSpPageNavi(pageNavi);
		}
	}
	initSpPageNavi(pageNavi){
		const links = pageNavi.querySelectorAll(".page__nav__link");
		const pageHead = document.querySelector(".page__head");
		const spPageNavi = document.createElement("div");
		const spPageNaviLabel = document.createElement("div");
		const spPageNaviSelect = document.createElement("select");

		links.forEach(link=>{
			const option = document.createElement("option");
			option.setAttribute("value", link.getAttribute("href"));
			option.innerText = link.innerText;
			if(link.classList.contains("current")){
				option.setAttribute("disabled", "disabled");
				option.setAttribute("selected", "selected");
				spPageNaviLabel.innerText = link.innerText;
			}
			spPageNaviSelect.appendChild(option);
		});
		spPageNavi.classList.add("page__spPageNavi");
		spPageNaviLabel.classList.add("page__spPageNavi__label");
		spPageNaviSelect.classList.add("page__spPageNavi__select");
		spPageNavi.appendChild(spPageNaviLabel);
		spPageNavi.appendChild(spPageNaviSelect);
		pageHead.parentNode.insertBefore(spPageNavi, pageHead.nextSibling);

		spPageNaviSelect.addEventListener("change", ()=>{
			const value = spPageNaviSelect.value;
			spPageNaviLabel.innerText = spPageNaviSelect.querySelector("option[value='"+value+"']").innerText;
			window.location.href = value;
		})
	}
}